var render, staticRenderFns
import script from "./MenuBase.vue?vue&type=script&lang=js"
export * from "./MenuBase.vue?vue&type=script&lang=js"
import style0 from "./MenuBase.vue?vue&type=style&index=0&id=a567ebba&prod&scoped=true&lang=css"
import style1 from "./MenuBase.vue?vue&type=style&index=1&id=a567ebba&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a567ebba",
  null
  
)

export default component.exports