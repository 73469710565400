<template lang="html">
  <div
    v-if="ready"
    id="vue-gf-platform-menu"    
  >
    <WebMenu 
      :menu-model="menuModel" 
      :profile-menu-model="profileMenuModel" 
      :menu-slide-direction="menuSlideDirection"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { VueLoggerPlugin } from '@gf/gf-logger'
import getMenuItemsData from './data/getMenuItemsData1.json'
import getProfileMenuItemsData from './data/getProfileMenuItemsData.json'
import WebMenu from './components/WebMenu.vue'

Vue.use(VueLoggerPlugin)

export default {
  name: 'GfPlatformMenu',
  components: {
    WebMenu,
  },
  props: {
    widgetType: {
      type: String,
      default: "WebMenu"
    },
    isDemoMode:{
      type: Boolean,
      default: false
    },
    menuModelData: {
      type: String,
      default: "{}"
    },
    profileMenuModelData: {
      type: String,
      default: "{}",
    },
    menuSlideDirection: {
      type: String,
      default: 'horizontal', // vertical/top or horizontal/side
    },
  },  
  data() {
    return {
      ready: false,
      menuModel: Object,
      profileMenuModel: Object,
    }
  },
  
  mounted() {
    this.$gfLogger.logInfo("Mounted GfPlatformMenu")
  },

  mounted() {
    this.initialize()  
  },

  methods: {
    initialize() {
      if (this.isDemoMode) {
        this.menuModel = getMenuItemsData;
        this.profileMenuModel = getProfileMenuItemsData
      } else {
        this.menuModel = JSON.parse(this.menuModelData);
        this.profileMenuModel = JSON.parse(this.profileMenuModelData)
      }
      this.ready = true;
    }
  },
}
</script>
