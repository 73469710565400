var menu = {
    resetMenus() {        
        const winW = $(window).width();
        $('.nav-overlay').trigger('touchend');
        if (winW > 750) {
            $('ul.gf-menu').removeAttr('style');
            $('ul.gf-menu').show();
            $("#mobileMenu").hide();  
            $('#nav-button-left').hide();          
            $('#top-nav.vision').removeAttr('style');
            $('.gf-menu-container').removeAttr('style');            
        } else {
            $('.gf-menu-container').css("display", "block");            
            $("#desktopMenu").hide();
            $('#nav-button-left').show(); 
        }
    },    

    resizeMenu() {
        var winW = $(window).width();
        if (winW < 750) {
            var menuH = $('.gf-menu-container').height();
            var marginTop = 0;
            if (menuH === 60) {
                marginTop = 25;
            }
            else if (menuH >= 70) {
                marginTop = menuH / 2;
            }

            $('.gf-menu').css({ 'width': '100%', 'position': 'absolute', 'margin-top': marginTop + 'px' });
        }
    },

    handleDropdownMobileMenu() {
        $('#mobileMenu .funraisin-dropdown-nav').on('click', 'a', function(e) {
            if ($(this).parent().find('ul').length > 0) {
                if ($(this).parent().find('ul').is(':visible')) {
                    if ($(e.target).hasClass('indicator')) {
                        $(this).parent().find('ul').hide();
                        $(this).find('.indicator').html("+");
                        return !1
                    }
                } else {
                    $(this).parent().find('ul').show();
                    $(this).find('.indicator').html("-");
                    return !1
                }
            }
        });
    },

    handleMobileMenuEvent(){
        $('.menu-toggle').click(function () {
            $('#navigation #event-dropdown').hide();
            $('.nav-overlay.system-o').hide();
            $('.nav-overlay.event-o').stop().fadeToggle();
            $('#mobileMenu').stop().slideToggle();
            $(this).toggleClass('active');
        });

        $('#navigation .go-btn').click(function () {
            $('ul.gf-menu').hide();
            $('.nav-overlay.event-o').hide();
            $('.nav-overlay.system-o').stop().fadeToggle();
            $('#navigation #event-dropdown').stop().slideToggle();
            $('.menu-toggle').removeClass('active');
        });

        $('.nav-overlay').bind("mouseup touchend", function (e) {
            const winW = $(window).width();

            if (winW > 750) {
                $('ul.gf-menu').show();
            }
            else {
                $('ul.gf-menu').hide();
            }

            $('#navigation #event-dropdown').slideUp();
            $('#event-dropdown').stop().slideUp(250)
            $('.nav-overlay.event-o').hide();
            $('.nav-overlay.system-o').stop().fadeOut();
            $('.menu-toggle').removeClass('active');
        });
    },

    mapMenuItems(menuItems) {
      if (menuItems.length == 0)
        return []

      return menuItems.map(i => {
        var subItemsData = menuItems.filter(sb => sb.ParentId === i.Id)
        var subItems = subItemsData.map(s => {
          return {
            Id: s.Id,
            ParentId :s.ParentId,
            Name: s.Name,
            MenuItemTypeId: s.MenuItemTypeId,                 
            TypeSettings: s.TypeSettings ? JSON.parse(s.TypeSettings) : 
              {
                Label : s.Name,
                Icon: '',
                LinkSettings: { Value : s.Url, Target : s.Target}
              }
          }
        })
          
        return {
          Id: i.Id,
          ParentId :i.ParentId,
          Name: i.Name,
          MenuItemTypeId: i.MenuItemTypeId,
          SubMenuItems : subItems,
          TypeSettings: i.TypeSettings ? JSON.parse(i.TypeSettings) : 
            {
              Label : i.Name,
              Icon: '',
              LinkSettings: { Value : i.Url, Target : i.Target}
            }
        }
      })
    },

}
export default menu