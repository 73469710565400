<template>
  <div>
    <div
      ref="menuWrapper"
      class="menu-wrapper menuBar"
    >
      <div
        ref="menuContainer"
        class="gf-menu-container gf-menu-background-bg gf-menu-style"
      >
        <div
          id="nav-header"
          tabindex="0"
        />

        <div class="desktop-logo">
          <WebLogo :logo="leftLogo" />
        </div><!--.desktop-logo -->

        <div
          class="nav-container-mid"
          tabindex="0"
        >
          <DesktopMenu
            :root-menu="dataMenuItems"
            :applied-css="appliedCss"
          />  
        </div><!-- .nav-container-mid -->

        <div
          id="nav-container"
          tabindex="0"
        >
          <div
            id="nav-overlay"
            class="bg"
          />
          
          <div id="nav-container-left">
            <div
              id="nav-button-left"
              class="nav-button"
              tabindex="0"
            >
              <span class="icon-bar" />
              <span class="icon-bar" />
              <span class="icon-bar" />
            </div><!-- $nav-container-left -->

            <div
              id="nav-content-left"
              tabindex="0"
              :class="['gf-menu-background-bg', getNavContentClass(menuSlideDirection)]"
            >
              <MobileLeftNav 
                :root-menu="dataMenuItems" 
                :menu-style="menuStyle"
              />
            </div>
          </div><!-- #nav-container-left -->

          <div class="mobile-logo">
            <WebLogo :logo="leftLogo" />
          </div><!-- .mobile-logo -->

          <div id="nav-container-right">
            <div
              id="nav-button-right"
              class="nav-button"
              tabindex="0"
            >
              <div class="go-profile go-profile-male" />
              <span
                class="arrow-down fa fa-chevron-down ml-1 text-muted font-weight-bold"
                aria-hidden="true"
              />
            </div><!-- #nav-button-right -->

            <div
              id="nav-content-right"
              tabindex="0"
              :class="['content-header', 'gf-menu-background-bg', getNavContentClass(profileMenuSlideDirection)]"
            >
              <MobileProfileNav
                :root-menu="profileMenuItems"
                :menu-style="menuStyle"
              />
            </div><!-- #nav-content-right -->
          </div><!-- #nav-container-right -->
        </div><!-- #nav-container -->

        <div class="desktop-logo">
          <WebLogo :logo="rightLogo" />
        </div><!-- .desktop-logo -->
      </div><!-- .gf-menu-container -->
    </div><!-- .menu-wrapper -->
  </div>
</template>

<script>
import _ from 'lodash'
import constants from "../common/constants"
import DesktopMenu from "./DesktopMenu.vue"
import MobileLeftNav from "./nav/MobileLeftNav.vue"
import MobileProfileNav from "./nav/MobileProfileNav.vue"
import WebLogo from './WebLogo.vue'
import menu from "./menu.js"
import '../assets/nav.css'

export default {
    name : "WebMenu",
    components: {
      DesktopMenu, 
      MobileLeftNav,
      MobileProfileNav,
      WebLogo,
    },
    props: {        
      menuModel: {
        type: Object,
        default: () => ({
          RenderedStyles: '',
        }),
      },
      profileMenuModel: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        menuItems: [],
        menuStyle: [],
        appliedCss: '',
        menuSlideDirection: constants.menuDirection.side,
        profileMenuSlideDirection: constants.menuDirection.side,
      }
    },
    computed: {
      rootMenu() {
        if (!_.isEmpty(this.menuItems)) {
          return menu.mapMenuItems(this.menuItems)
        }
        return []
      },

      dataMenuItems(){
        return this.rootMenu.filter(item => {
          return item.ParentId === null && item.MenuItemTypeId !== constants.MenuItemTypeId_Logo
        })
      },

      profileMenuItems() {
        if (this.profileMenuModel && _.has(this.profileMenuModel, 'MenuItems')) {
          return menu.mapMenuItems(this.profileMenuModel.MenuItems)
        }
        return []
      },

      mobilelogo() {        
        if (this.leftLogo) return this.leftLogo
        if (this.rightLogo) return this.rightLogo

        return null
      },

      leftLogo() {
        return this.getLogoByPosition("left")
      },

      rightLogo() {
        return this.getLogoByPosition("right")
      },

      renderedStyles() {
        return this.renderStyles(this.menuModel.RenderedStyles)
      },

    },
    
    created() {             
      if (this.menuModel) {
        this.menuItems = _.get(this.menuModel, 'MenuItems')
        this.menuStyle = _.get(this.menuModel, 'MenuStyleModel')
        this.appliedCss = this.menuModel.CssClass ? this.menuModel.CssClass : "text-center "
        this.menuSlideDirection = this.setMenuSlideDirection(this.menuModel)

        if (!_.isEmpty(this.menuItems)) {
          var logos = this.menuItems.filter(item => {
            return item.MenuItemTypeId === constants.MenuItemTypeId_Logo
          })

          this.appliedCss = (logos.length > 0 ? "gf-menu-position-logo " : "gf-menu-position ") + this.appliedCss
        }
      }

      if (this.profileMenuModel) {
        this.profileMenuSlideDirection = this.setMenuSlideDirection(this.profileMenuModel)
      }
    },      
    mounted() {
      this.registerMobileMenuEvents()
      this.renderMenuStyle(this.menuModel.RenderedStyles)
    },

    methods: {
      renderMenuStyle(styleStr) {
        if (_.isEmpty(styleStr)) {
          return
        }

        var stylesArr = styleStr.split(';')

        if (stylesArr.length > 0) {
          for (var i = 0; i < stylesArr.length; i++) {
            var styleProps = stylesArr[i].split(':')
            if (!_.isEmpty(styleProps[0])) {
              this.$refs.menuWrapper.style.setProperty(`--nav-menu-${styleProps[0].trim()}`, styleProps[1])

              if (styleProps[0].trim() === 'padding-top') {
                this.$refs.menuWrapper.style.setProperty(`--nav-menu-logo-margin-top`, '-' + styleProps[1].trim())
              }
            }
          }
        }

        if (_.has(this.menuStyle, 'MenuForegroundColor') && !_.isEmpty(this.menuStyle.MenuForegroundColor)) {
          this.$refs.menuWrapper.style.setProperty(`--nav-menu-foreground-color`, this.menuStyle.MenuForegroundColor)
        }

        this.$refs.menuWrapper.style.setProperty(`border-radius`, '0px')
      },

      getNavContentClass(menuDirection) {
        switch (menuDirection) {
          case constants.menuDirection.horizontal:
          case constants.menuDirection.side:
            return 'slide-horizontal'
          case constants.menuDirection.vertical:
          case constants.menuDirection.top:
            return 'slide-vertical'
          default:
            return 'slide-horizontal'
        }
      },

      setMenuSlideDirection(menuModel) {
        return _.get(menuModel, 'MenuSlideDirection', constants.menuDirection.side)
      },

      getLogoByPosition(position){
        var logos = this.rootMenu.filter(item => {
          return item.MenuItemTypeId === constants.MenuItemTypeId_Logo && item.TypeSettings.LogoPosition === position
        })
        
        var logo = logos[0]
        
        if (logo && logo.TypeSettings !== '' && typeof logo.TypeSettings !== 'object') {
          logo.TypeSettings = JSON.parse(logo.TypeSettings)
        }
        return logo
      },

      registerMobileMenuEvents() {
        if (typeof $ != 'undefined') {
          $(function () {
            menu.resizeMenu()
            menu.handleDropdownMobileMenu()
          })

          $(document).ready(function () {
            menu.resetMenus()
            menu.handleMobileMenuEvent()
          })

          $(window).resize(function () {
            menu.resetMenus()
          })
        }
      }
    },

}
</script>

<style>
span.pull-right.indicator {
    font-size: 16pt;
    font-weight: bold;
}

.pull-right {
    float: right!important;
}

.profile {
    transform: translateY(-50%);
    top: 50%;
    position: relative;
}

.menuBar {
    display: flex;    
    border-radius: 5px 5px 0 0;
}

.gf-menu-container {
    width: 100%;
    background: #e3ca00;
    height: 100%;
    padding-top: var(--nav-menu-padding-top);
    padding-bottom: var(--nav-menu-padding-bottom);
    justify-content: var(--nav-menu-justify-content);
    border-radius: 0px !important;
}

.profileSection{
    width: 5%;
}

.fa-chevron-down {
    font-size: 8pt;
}

.gf-menu-position-logo {
    display: flex;
}

/* ========================================================
                    NAVIGATION
======================================================== */
.mobile-menu-bar {
    position: relative;
}

div#navigation:after {
    content: '';
    clear: both;
    display: block;
}

.content-header {
    
}

div.go-btn > span.fa {
    font-size: 7px;
    float: right;
    margin: 10px 0 0 3px;
}

div#navigation ul li {
    display: block;
    float: none;
    padding: 0;
    display: inline-block;
}

div#navigation ul li a {
    margin: 0;
    padding: 0.4em 1.4em;
    text-align: left;
}

.menu-toggle > div:nth-child(2) {
    margin: 5px 0 5px 0;
}

.menu-toggle > div {
    width: 100%;
    height: 4px;   
    border-radius: 0;
}

.gf-item-display {
    align-items: center !important;
    display: inline-flex !important;
}

.menu-toggle {
    width: 20px;
    height: 30px;
    float: left;
    padding: 0;
    margin-bottom: 0px;
    cursor: pointer;
    margin-top: 0px;
}

.mobile-menu-bar {
    padding: 5px 10px;
    display: none;
}

.mobile-menu-bar:after {
    clear: both;
    content: '';
    display: block;
}

#mobileMenu{
    position: inherit;
}

#mobileMenu li{
    display:block !important;
    margin: 0px 50px;
    margin-bottom: 10px;
}

.gf-menu-container {
    min-height: 50px !important;
    display: flex;
}


.gf-menu-position {
    float: right;
    width: 100%;
    display: flex;
    justify-content: center;
}


ul#mobileMenu {
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
    padding-bottom: 20px;
}

ul#desktopMenu {
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
}

ul.gf-menu li {
    float: none;
    display: inline-block;
    margin-right: 7px;
}

#navigation .gf-menu-container .go-btn {
    cursor: pointer;
    float: right;
    margin-top: 0;
    margin-left: 0;
}

div#top-nav.vision {
    display: none;
}

.logoMenuItem {
    z-index: 90;
}

.mobile-logo {
  position: relative;
  width: 100%;
  height: var(--nav-menu-height);
}

.desktop-logo {
  top: 0px !important;
  position: relative;
  height: var(--nav-menu-height);
  margin-top: var(--nav-menu-logo-margin-top);
}

.mobile-logo .logoMenuItem {
    display: none;
}

.desktop-logo .logoMenuItem {
    display: inline;
}

@media (min-width:768px){

    .logoMenuItem{
        position: relative;
    }

    .logoMenuItem img{
        transform: translateY(-50%);
        top: 50%;
        position: relative;
    }

    div#navigation ul.gf-menu > li{
        transform: translateY(-50%);
        top: 50%;
        position: relative;
        height: fit-content;
    }

    .logo-left img {
        padding-left: 15px;
    }

    .logo-right img {
        margin-right: 85px;
    }
}

/* MOBILE SCREEN */
@media screen and (max-width:767px) {
    /* styling dropdown menu on the mobile mode */

    .gf-menu-container {
        width: 100%;
    }

    /*hide menu profile icon on mobile menu*/
    .profileSection{
        display: none;
    }

    ul.gf-dropdown-menu {
        position: inherit;
    }

    ul.gf-menu-position-logo {
        display: block;
    }

    ul.gf-menu {
        display: none;
        padding-bottom: 20px;
    }

    .mobile-menu-bar {
        top: 50%;
        transform: translateY(-50%);
    }

    

    .mobile-logo .logoMenuItem {
        display: flex;
    }

    .desktop-logo .logoMenuItem {
        display: none;
    }

    /*show the logo on the mobile mode*/
    .mobile-logo .logoMenuItem {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: block;
    }

    #navigation {
        z-index: 999;
        position: relative;
    }
    div#navigation div.gf-menu-container {
        border-radius: 0;
        /* position: relative; */
    }

    .gf-menu-position {
        position: absolute;
    }

    #navigation .gf-menu-container .go-btn {
        vertical-align: middle;
        height: 25px;
        margin-top: 2px;
    }

    div#navigation ul li a {
        /* margin-bottom: 6px !important; */
        /* border-radius: 0; */
        padding: 0.4em 1.4em;
        text-align: left;
    }

    div#navigation ul li {
        display: block;
        float: none;
        padding: 0;
        margin: 0px 0px 6px 0 !important;
    }

    .mobile-menu-bar {
        display: block;
    }

    .gf-menu-container {
        
    }

    ul.gf-menu {
        display: none;
    }

    .mobile-menu-bar > .menu-toggle > svg {
        margin-top: 0;
        width: 30px;
        height: 30px;
    }

    .mobile-menu-bar > .menu-toggle {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
    }
}

@media screen and (max-width:767px) {
    div#top-nav.vision {
        display: block;
        position: relative;
    }

    div#top-nav.vision ul li a {
        display: block;
        padding: 0.3em 1.8em;
        text-align: right;
    }

    div#top-nav.vision ul li img
    {
        padding: 0.3em 1.8em;
        text-align: right;
        margin-bottom: 6px !important;
    }
    div#top-nav.vision ul li {
        float: none;
        margin: 0;
        padding: 0;
    }

    div#top-nav.vision ul {
        float: none;
        margin: 0;
        padding: 0;
    }

    div#top-nav.vision:after {
        clear: both;
        content: '';
        display: block;
    }

    #top-header {
        width: 100%;
        cursor: pointer;
        display: none;
    }

    #top-nav ul li a {
        display: block;
        text-align: center;
        float: none;
    }

    #top-nav ul li {
        display: block;
        float: none;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    #top-nav ul {
        float: none;
        margin: 0;
        padding: 0;
    }

    #top-nav:before {
        clear: both;
        content: '';
        display: block;
    }

    #top-nav {
        float: none;
        display: block;
        width: 100%;
        display: none;
    }

    #top-nav ul {
        text-align: right;
        display: inline;
        float: right;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}
/* =========== / END NAVIGATION =========== */

.go-profile-male {
    background-image: url(https://cdn.gofundraise.com.au/all/placeholder-images/profile-m.jpg);
}

.go-profile {
    background-size: 25px 25px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    display: inline-block;
}

/* drop down menu */
ul.gf-dropdown-menu {
    display: none;
}
ul.gf-dropdown-menu {
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 0px rgb(0 30 89 / 5%);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,30,89,0.05);
    box-shadow: 0px 0px 20px 0px rgb(0 30 89 / 5%);
    margin-left: 10px;
    padding: 15px 20px;
}
ul.gf-dropdown-menu {
    margin: 0;
    padding: 0;
    z-index: 99;
    position: absolute;
    top: 35px;
    margin-left: 10px;      
    padding-top: 10px;
}
ul.gf-dropdown-menu li.gf-item {
    min-width: 230px;
    z-index: 1;
    margin-left: 7px;
    margin-bottom: 7px;
}

ul.gf-dropdown-menu li a {
    padding: 10px !important;
}
/* do not add shadow on the mobile menu */
ul#mobileMenu .gf-dropdown-menu {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    position: inherit;
}
/* end drop down menu */

</style>