<template>
  <div>
    <ul>
      <li 
        v-for="(item, index) in menuItems"
        :key="index"
        :class="getMenuListItemClass(item)"
        :style="getMenuListItemStyle(item)"
      >
        <div class="gf-menu-item-wrapper">
          <a
            :href="item.TypeSettings.LinkSettings.Value"
            :style="[getAnchorStyle(), getButtonAnchorStyle(item.MenuItemTypeId, item.TypeSettings)]"
            :target="item.TypeSettings.LinkSettings.Target"
          >
            <span>
              <template v-if="hasIcon(item)">
                <i :class="['fa ' + item.TypeSettings.Icon]" />
              </template>
              {{ item.TypeSettings.Label }}
            </span>
          </a>
          <a href="javascript:;">
            <span
              v-if="item.SubMenuItems.length > 0"
              class="pull-right indicator"
              @click="toggleSubmenu(item)"
            >
              {{ (item.subMenuCollapsed ? '-' : '+') }}
            </span>
          </a>
        </div>
        <template v-if="item.subMenuCollapsed">
          <ul
            v-if="item.SubMenuItems.length >0"
            class="gf-submenu pl-4"
          >
            <li
              v-for="(subItem, subIndex) in item.SubMenuItems"
              :key="subIndex"
              :class="['gf-item gf-item-display', getButtonClassByType(getButtonType(subItem))]"
              :style="getButtonStyle(subItem.MenuItemTypeId, subItem.TypeSettings)"
            >
              <a
                :href="subItem.TypeSettings.LinkSettings.Value"
                :style="[getAnchorStyle(), getButtonAnchorStyle(subItem.MenuItemTypeId, subItem.TypeSettings)]"
                :target="subItem.TypeSettings.LinkSettings.Target"
              >
                <template v-if="hasIcon(subItem)">
                  <i :class="['fa ' + subItem.TypeSettings.Icon]" />
                </template>
                {{ subItem.TypeSettings.Label }} 
              </a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import MenuBase from '../MenuBase.vue'

export default {
  name: 'MobileProfileNav',
  mixins: [MenuBase],
  data() {
    return {
      menuItems: [],
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.rootMenu.length > 0) {
        const menus = this.rootMenu
        this.menuItems = menus
      }
    },
  }
}
</script>
