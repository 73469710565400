<script>
import _ from 'lodash'
import constants from "../common/constants"

export default {
  props: {        
    rootMenu: {
      type: Array,
      default: () => [],
    },
    appliedCss: {
      type: String,
      default: "",
    },
    menuStyle: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getAnchorStyle() {
      var style = {}
      if (_.has(this.menuStyle, 'MenuForegroundColor')) {
        style['color'] = this.menuStyle.MenuForegroundColor + ' !important'
      }

      return style
    },

    getButtonAnchorStyle(menuItemTypeId, settings) {
      if (menuItemTypeId !== constants.MenuItemTypeId_Button) 
        return {}
      
      var style = this.getButtonStyle(menuItemTypeId, settings)
      if (_.has(settings, 'Settings.HorizontalSize')) 
          style['width'] = settings.Settings.HorizontalSize + 'px !important;'

      style['border'] = 'none !important'

      delete style['background-color']

      return style
    },

    getButtonStyle(menuItemTypeId, settings){        
      if (menuItemTypeId !== constants.MenuItemTypeId_Button) 
        return {}

      var style = {
        'border-radius': _.get(settings, 'Settings.borderRadius', 0)
      }

      if (_.get(settings, 'ButtonStyles.Type') === "driver" || _.get(settings, 'ButtonStyles.Type') === "secondary")
        style['background-color'] = _.get(settings, 'ButtonStyles.Colour')
      else
        style['border'] = '1px solid ' + _.get(settings, 'ButtonStyles.Colour')

      return style
    },

    getButtonType(item){
      return _.get(item, 'TypeSettings.ButtonStyles.Type', '')
    },

    getButtonClassByType(type) {
      switch (type) {
        case 'driver':
          return 'gf-driver-button'
        case 'driverHollow':
          return 'gf-driver-button-hollow'
        case 'secondary':
          return 'gf-secondary-button'
        case 'secondaryHollow':
          return 'gf-secondary-button-hollow'
        default:
          return ''
      }
    },

    getMenuListItemClass(item) {
      var defaultClass = ['gf-top-menu-item gf-item gf-item-display funraisin-dropdown-nav']

      if (item.SubMenuItems.length == 0) {
        defaultClass.push(this.getButtonClassByType(this.getButtonType(item)))
      }

      return defaultClass
    },

    getMenuListItemStyle(item) {
      return item.SubMenuItems.length > 0 ? '' : this.getButtonStyle(item.MenuItemTypeId, item.TypeSettings)
    },

    toggleSubmenu(item) {
      item.subMenuCollapsed = !item.subMenuCollapsed
    },

    hasIcon(item) {
      return _.has(item, 'TypeSettings.Icon') && !_.isNil(item.TypeSettings.Icon)
    }
  },
}
</script>

<style scoped>
.gf-top-menu-item {
  display: block !important;
}

.gf-menu-item-wrapper {
  width: 100%;
  display: flex !important;
  justify-content:space-between;
  align-items: center;
}

.gf-menu-item-wrapper a:first-child {
  flex-grow: 1;
}

.gf-submenu {
  position: relative !important;
}
</style>

<style>
ul.gf-submenu li.gf-item a {
    border: 0px !important;
    background: unset !important;
}

ul.gf-submenu li.gf-item:last-child a {
    border: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
</style>