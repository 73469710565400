<template>
  <ul 
    id="desktopMenu"
    :class="['gf-menu gf-menu-eventcreator ' + appliedCss]"
  >
    <li
      v-for="(item, index) in rootMenu"
      :key="index"
      :class="['gf-item gf-item-display',getButtonClassByType(getButtonType(item)) ]"        
      :style="getButtonStyle(item.MenuItemTypeId, item.TypeSettings)"
    >
      <a
        :href="item.TypeSettings.LinkSettings.Value"
        :style="getAnchorStyle(item.MenuItemTypeId, item.TypeSettings)"
        :target="item.TypeSettings.LinkSettings.Target"
        class="gf-menu-item"    
      >
        <i :class="['fa ' + item.TypeSettings.Icon]" /> 
        {{ item.TypeSettings.Label }} 
        <span
          v-if="item.SubMenuItems.length >0"
          class="fas fa-chevron-down pl-1"
        />
      </a>
      <!-- SUBMENU ITEM  -->
      <ul
        v-if="item.SubMenuItems.length >0"
        class="gf-dropdown-menu"
      >
        <li
          v-for="(subItem, subIndex) in item.SubMenuItems"
          :key="subIndex"
          :class="['gf-item gf-item-display',getButtonClassByType(getButtonType(subItem)) ]"        
          :style="getButtonStyle(subItem.MenuItemTypeId, subItem.TypeSettings)"
        >
          <a
            :href="subItem.TypeSettings.LinkSettings.Value"
            :style="getAnchorStyle(subItem.MenuItemTypeId, subItem.TypeSettings)"
            :target="subItem.TypeSettings.LinkSettings.Target"          
          >
            <i :class="['fa ' + subItem.TypeSettings.Icon]" /> 
            {{ subItem.TypeSettings.Label }} 
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>

import Base from "./MenuBase.vue"

export default {
    name: "DesktopMenu",
    mixins: [Base], 
}
</script>

<style scoped>
.gf-menu-item {
  padding: 10px;
}

.gf-menu-item + ul.gf-dropdown-menu li.gf-item {
  display: block !important;
}

.gf-menu-item:hover + ul.gf-dropdown-menu {
  display: block;
}

.gf-menu-item:focus-within + ul.gf-dropdown-menu {
  display: block;
}

.gf-menu-item + ul.gf-dropdown-menu:hover {
  display: block;
}
</style>