export default {
  "MenuItemTypeId_Button": 143,
  "MenuItemTypeId_Logo": 172,
  menuDirection: {
    horizontal: 'horizontal',
    side: 'side',
    vertical: 'vertical',
    top: 'top',
  },
}
