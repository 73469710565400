<template>
  <a
    v-if="logo"
    :class="['logoMenuItem logo-' + logo.TypeSettings.LogoPosition]"
    :href="logo.TypeSettings.LinkSettings.Value"
    :target="logo.TypeSettings.LinkSettings.Target"
  >         
    <img
      :style="{width: logo.TypeSettings.LogoImageSize}"
      :src="getLogoCdn(logo.TypeSettings.LogoUrl)"
    ></a>
</template>
<script>
export default {
    name : "WebLogo",
    props: {        
        logo: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
      getMetaByName(name) {
        var meta = document.querySelector("meta[name='" + name + "']")
        if(meta){
          return meta.getAttribute('content');
        }
        return "";
      },

      getLogoCdn(logoUrl){
        if(!logoUrl) return "";

        let PROD = "PROD";
        let environment = this.getMetaByName('gfEnvironment');
        let baseDomain = this.getMetaByName('baseDomain');
        let cdnUrl = this.getMetaByName('gfCdnBaseUrl');

        if(cdnUrl === "") return logoUrl;

        var baseUrl = environment === PROD ? baseDomain : "gofundraise.com.au";

        cdnUrl = cdnUrl.replace(/^(https|http)\:/, '')
        var url = logoUrl.replace(baseUrl, cdnUrl)
                        .replace(/^www\./, "")
                        .replace(/^(https|http)\:\/\//, '')

        if (environment !== PROD) {
          url = url.replace(/^\/\/www\./, '')
        }

        url = url.replace(/^\/\/(www.)?\/\//, '\/\/')

        if (/^\/\//.test(url) === false && /^(https|http)/.test(url) === false) {
          url = '//' + url
        }

        return url
      }
    }
}
</script>